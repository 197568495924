//RUST IMAGES

import rustlastcheat from '../images/rust/rustlastcheat.webp';
import rustdisarray from '../images/rust/rustdisarrayfivestar.webp';
import rustdivision from '../images/rust/rustdivisionfivestar.webp';
import rustdisconnect from '../images/rust/rustdisconnectfivestar.webp';
import rustfluent from '../images/rust/rustfluentfivestar.webp';
import rustquantumpublic from '../images/rust/rustquantumpublicfivestar.webp';
import rustquantumprivate from '../images/rust/rustquantumprivatefivestar.webp';
import rustrustsense from '../images/rust/rustsensefivestar.webp';
import rustaccounts from '../images/rust/rustaccountsfivestar.webp';

//TARKOV IMAGES
import tarkovcoffeelite from '../images/tarkov/tarkovcoffeelitefivestar.webp';
import tarkovcoffeefull from '../images/tarkov/tarkovcoffeefullfivestar.webp';
import tarkovterralabs from '../images/tarkov/tarkovterrafivestar.webp';
import tarkovquantumprivate from '../images/tarkov/tarkovquantumprivatefivestar.webp';

//RAINBOW SIX SIEGE IMAGES
import rainbowklar from '../images/rainbow/rainbowklarfivestar.webp';
import rainbowarmy from '../images/rainbow/rainbowcheatarmyfivestar.webp';

//DAYZ IMAGES
import dayzintel from '../images/dayz/dayzintellesensefivestar.webp';

//SPOOFER IMAGES
import spooferexception from '../images/spoofer/spooferexception.webp';
import spooferperm from '../images/spoofer/spooferperm.webp';

import permimg from '../images/spoofer/permspooferimg.webp';

//CSGO IMAGES
import csgofecurity from '../images/csgo/csgofecurityfivestar.webp';

//APEX IMAGES
import apexfecurity from '../images/apex/apexfecuirtyfivestar.webp';

//BATTLEBIT IMAGES
import battlefecurity from '../images/battlebit/battlebitfecuirtyfivestar.webp';

//MW IMAGES
import mwfecurity from '../images/mw/mwfecuirtyfivestar.webp';
import mwfecurityunlocker from '../images/mw/mwfecuirtyunlockerfivestar.webp';

//VALORANT IMAGES
import valcoffee from '../images/valorant/valorantcoffeefivestar.webp';
import valfecurity from '../images/valorant/valorantcfecuirtyfivestars.webp';




export const data = [

    //RUST
    {
        name: "Last Cheat V3",
        game: "Rust",
        image: rustlastcheat,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/LastCheat-V3",
        media: [
            { img: rustlastcheat },

          ],
        day: "7",
        week: "35",
        month: "65",
        seopath: 'data-sellpass-product-path="Cpro" data-sellpass-domain="putdovhsunybbxispxqo1rfgzdh8ul8m.sellpass.io"',
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "On / Off",
                    "Bullet que",
                    "Instant hit",
                    "Prediction Dot",
                    "Target Snapline",
                    "Ignore FOV",
                    "Hitboxes",
                    "Hit Chance",
                    "FOV",
                    "Manipulation",
                    "Desync Bar",
                    "Auto Shoot",
                    "Wait For Desync",
                    "Auto Reload",
                    "Reload Bar",
                    "One Tap Eoka",
                    "No Spread",
                    "Pierce",
                    "Fast Bullet",
                    "No Invalid Thick Bullet",
                    "Bullet Tracers",
                    "Recoil Slider",
                    "Bullet TP",
                    "Bullet Queue",
                    "Water Reflect",
                    "Desync",
                    "Stationary Desync Amount (Manip)",
                    "Constant Desync Amount (Manip)",
                    "Ignore Scientists",
                    "Ignore Team",
                    "Ignore Sleepers",
                    "Ignore Disconnected",
                    "Ignore Dead",
                    "Ignore Patrol"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Players",
                    "Name",
                    "Distance",
                    "Weapon Text",
                    "Flags",
                    "Team ID",
                    "Steam ID",
                    "Hotbar Text",
                    "Hotbar Images",
                    "Clothing Text",
                    "Clothing Images",
                    "Skeleton",
                    "Healthbar",
                    "Box",
                    "3D Box",
                    "SnapLines",
                    "Color options",
                    "Chams",
                    "Visibility Checks",
                    "World Visuals",
                    "Cargoship",
                    "Patrol Helicopter",
                    "Dropped Item",
                    "Corpse",
                    "Minicopter",
                    "Car",
                    "Boat",
                    "Submarine",
                    "Scrap Helicopter",
                    "Locked Crate",
                    "Sam Site",
                    "Auto Turret",
                    "Hemp",
                    "Stone Ore",
                    "Sulfur Ore",
                    "Metal Ore",
                    "Stashes",
                    "Tool Cupboard"
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Anti-Flyhack-Kick",
                    "Anti-Flyhackbar",
                    "Spiderman + Nofall",
                    "Infinite Jump",
                    "Admin Flags",
                    "Block Server Commands",
                    "Always sprint",
                    "Always attack",
                    "Spinbot",
                    "Gunshot Spammer",
                    "Walk On Water",
                    "Walk Through Trees",
                    "Walk Through Players",
                    "Auto-Upgrade",
                    "Hammer Spam",
                    "Hammer Hit Anything",
                    "Auto Pickup",
                    "Auto Farm",
                    "Bindable Noclip",
                    "Bindable DebugCamera",
                    "Ambient"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SECURE BOOT & NO HYPERVISORS"},
        ],
    },
    {
        name: "Accounts",
        game: "Rust",
        image: rustaccounts,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Rust-Accounts-Full-Access",
        media: [
            { img: rustaccounts },

          ],
        special: "17",
        seopath: 'data-sellpass-product-path="Accounts" data-sellpass-domain="putdovhsunybbxispxqo1rfgzdh8ul8m.sellpass.io"',
        features: [
            {
                "title": "INCLUDES",
                "items": [
                    "Full Access Steam Account"
                  ]
            },
        ],
        requirments: [
            {"other": "Steam Installed"},
        ],
    },
    {
        name: "Division",
        game: "Rust",
        image: rustdivision,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Division",
        media: [
            {"img": rustdivision},
        ],
        day: "8",
        week: "32",
        month: "65",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Enabled [keybind]",
                    "FOV",
                    "Smoothness",
                    "Hit Chance",
                    "Bone [Head / Chest / Closest / Stomach]",
                    "Silent",
                    "Memory",
                    "Remove FOV",
                    "Target Team",
                    "Target NPC",
                    "Lock Target",
                    "Remove Aim Line",
                    "HITBOX OVERRIDE [Head / Body / Random]"
                  ]
            },
            {
                "title": "ESP",
                "items": [
                    "Player",
                    "Box [Normal / Corner]",
                    "Chams [Pink / Black / White / Red / Red2]",
                    "Skeleton",
                    "Name",
                    "Distance",
                    "Weapon",
                    "Team",
                    "Team ID",
                    "Flags [aim, mount, crawl, wound, team, division, safezone]",
                    "Health",
                    "Sleepers",
                    "Scientists",
                    "Hotbar / Clothing",
                    "Out of FOV Arrows",
                    "Look Direction",
                    "Looking At You",
                    "Animals",
                    "Resources",
                    "Lootables",
                    "Vehicles",
                    "Traps",
                    "Foods",
                    "World",
                    "TC Auth List",
                    "Auto Turret Auth List",
                    "Dropped Items"
                  ]
            },
            {
                "title": "RADAR",
                "items": [
                    "Enabled",
                    "Players",
                    "Entities",
                    "Size",
                    "Distance"
                  ]
            },
            {
                "title": "WEB RADAR",
                "items": [
                    "Web Link for online radar for friends",
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Weapon",
                    "No Recoil [slider]",
                    "No Spread [slider] + shotguns",
                    "No Sway",
                    "Force Automatic",
                    "Instant Eoka",
                    "Instant Bow",
                    "Instant Compound Bow",
                    "Extended Melee Range",
                    "No Block Sprint",
                    "Melee Hit Assist",
                    "Player",
                    "Admin Flags",
                    "Block Server Commands",
                    "Crosshair [Dot / Normal +]",
                    "FOV Changer",
                    "Bright Night",
                    "Bright Caves",
                    "Starry Nights",
                    "Always Day",
                    "Instant Revive",
                    "Instant Untie",
                    "Unlock Mountable Angles",
                    "Long Neck",
                    "Movement",
                    "Spiderman",
                    "Infinite Jump",
                    "No Fall",
                    "No Collision",
                    "Suicide",
                    "Fake Lag",
                    "If player looking at you, auto fakelag",
                    "Fly Hack [Noclip]",
                    "Interactive DebugCamera"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Configs",
                    "Custom Config System",
                    "Players",
                    "Player List",
                    "Settings",
                    "Draw DEBUG ESP",
                    "Cheat Indicators",
                    "Menu Bind",
                    "Combat Mode"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "DIRECTX 11"},
        ],
    },
    {
        name: "Fluent",
        game: "Rust",
        image: rustfluent,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Fluent",
         media: [
            {"img": rustfluent},
        ],
        day: "12",
        week: "50",
        month: "100",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Draw FOV Circle",
                    "Smoothness",
                    "Aim Spot",
                    "Auto Shoot",
                    "Rapid Fire",
                    "Bullet TP",
                    "Patrol Heli Bullet TP",
                    "Instant Hit",
                    "Auto Shoot Wait For Instant Hit"
                  ]
            },
            {
                "title": "ESP",
                "items": [
                    "Players: Name, Box, Health Bar, Distance, Skeleton",
                    "Active Item: Icons, Game Names, and Shortened Names for quick identification.",
                    "Enemy Color Override: Customize the visual representation of enemy players.",
                    "Hotbar: Easily view the items in your character's hotbar.",
                    "Sleepers: Identify sleeping players for strategic purposes.",
                    "Radar: Enable radar tracking of players, sleepers, and wounded characters.",
                    "NPCs: Name, Box, Health Bar, Distance, Skeleton",
                    "Animals: Name, Icons, Health Bar, Distance",
                    "Vehicles: Name, Icons, Health Bar, Distance",
                    "Ores: Name, Distance",
                    "Collectibles: Name, Distance",
                    "Stash: Name, Distance",
                    "Crates: Name, Distance",
                    "AI: Name, Health Bar, Distance (Cargo, Patrol Heli, Bradley)",
                    "Deployables: Name, Distance (Tool Cupboards, All Workbenches, Sleeping bags)",
                    "Items: Name, Distance (Melee Weapons, Tier 1/2/3 Weapons, Explosives, Resources, Tools, Medical, Ammo,",
                    "Traps: Name, Distance (Shotgun Trap, Flame Turret, SAM Site, Auto Turrets, Bear Traps, Landmines)",
                    "Show Authorized Traps: Display authorized traps for better base defense.",
                    "Raids: Name, Distance (All Raid Items, Seconds of Last Raid Item)"
                  ]
            },
            {
                "title": "OTHER",
                "items": [
                    "View Model Hand Chams: Customize weapon and hand appearance for various modes.",
                    "View Model Weapon Chams: Customize weapon appearance for various modes.",
                    "No Recoil, No Spread, No Sway",
                    "Faster Bullets",
                    "Instant Eoka",
                    "Fast Bow",
                    "Instant Compound Charge",
                    "Auto Reload, Faster Auto Reload: Automate and expedite the reload process.",
                    "Better Penetration: Increase bullet penetration through surfaces.",
                    "Shoot Through Wounded: Enable bullets to pass through wounded players.",
                    "Shoot Through Teammates: Allow bullets to pass through friendly players without causing harm.",
                    "Hit Override: Override hit detection for headshots and chest shots.",
                    "Silent Melee: Execute melee attacks silently for stealth gameplay.",
                    "Weapon Spam: Rapidly fire weapons for enhanced combat efficiency."
                  ]
            },
            {
                "title": "MOVEMENT",
                "items": [
                    "Spider-man: Gain the ability to climb walls and surfaces.",
                    "No Fall",
                    "Infinite Jump",
                    "Jesus Mode: Walk on water surfaces without sinking.",
                    "Omni-sprint: Run sideways to confuse opponents during combat.",
                    "Anti Fly-hack Kick",
                    "Noclip Collisions, No Tree Collisions, No Player Collisions: Overcome collision limitations for smoother movement.",
                    "Interactive Debug with Shoot Indicators: Receive visual cues for optimal shooting opportunities."
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Admin Flag: Enable admin privileges for special access.",
                    "Block Server Commands: Prevent unauthorized server command usage.",
                    "Auto Upgrade: Automatically upgrade structures when materials are available.",
                    "Disable After (X) Seconds: Set a timer to disable certain features after a defined duration.",
                    "Desync: Adjust desynchronization settings for better gameplay performance.",
                    "On Key Max Desync Override",
                    "Instant Loot",
                    "Loot Stashes Without Unhiding",
                    "No Screen Shake",
                    "Always Hit Hot Spot",
                    "Silent Farm:",
                    "Auto Farm Barrel"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Create Your Own Config/Use Someone Else's Config: Save personalized configurations for future use.",
                    "Save and Load Config: Store and retrieve customized settings easily."
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "BUILT IN SPOOFER"},
        ],
    },
    {
        name: "Disconnect",
        game: "Rust",
        image: rustdisconnect,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Disconnect-",
        media: [
            {"url": "https://www.youtube.com/embed/ETQYQnYMlJg?si=8DmZIPOyFgiiVCvX"},
        ],
        day: "10",
        days: "21.99",
        week: "44.99",
        month: "84.99",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Aimbot Always",
                    "Silent Aim",
                    "Silent Aim Spread %",
                    "Target Selection (team, npc, wounded, patrol heli, sleepers)",
                    "Lock Target",
                    "Target Behindwall",
                    "Bullet TP",
                    "Mounted Magic",
                    "Manipulation",
                    "Desync",
                    "Max Desync %",
                    "Draw FOV",
                    "Fake Shoot",
                    "Instant Eoka",
                    "Can Always Shoot",
                    "Force Automatic",
                    "Instant Bow",
                    "Silent Melee",
                    "Far Melee",
                    "Pierce Materials",
                    "Auto Reload",
                    "Custom Headshot Sound",
                    "Custom Hitsound",
                    "Auto Shoot",
                    "Auto Shoot Flags (silent, no animation, always shoot)",
                    "Auto Shoot Type (always, powershot, queue, always/rapid)",
                    "Minimum Bullet Queue",
                    "Hitbox Override (head, chest, stomach, random, random body)",
                    "Recoil X %",
                    "Recoil Y %",
                    "Spread %",
                    "Shotgun No Spread",
                    "Thick Bullet 0-1",
                    "Fast Bullet",
                    "Instant Hit",
                    "Fake Hit Distance",
                    "Visual Bullet Size"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Player ESP",
                    "ESP Flags (wounded, sleepers, team, enemy)",
                    "Box",
                    "Box Type (corner, full)",
                    "Skeleton",
                    "Healthbar",
                    "Healhbar Type (bottom, side)",
                    "Name",
                    "Distance",
                    "Held Item",
                    "Target Line",
                    "Filled Box",
                    "Chams",
                    "Cham Type (vischeck, glow, rainbow, wireframe, cancer, gold)",
                    "TeamID",
                    "Player Distance",
                    "Out Of FOV Indicators",
                    "OOF Type (static color, breathing)",
                    "Bullet Tracers",
                    "Bullet Tracer Length",
                    "Crosshair (dot, rainbow, csgo)",
                    "Sprite Hotbar (name, amount, durability)",
                    "Viewmodel Chams",
                    "Dropped Item Chams",
                    "Local Player Chams",
                    "Dropped Items",
                    "Player Corpse",
                    "NPC Esp",
                    "Box",
                    "Box Type (corner full)",
                    "Health",
                    "Health Type (bottom, side)",
                    "Name",
                    "Distance",
                    "Held Item",
                    "Chams",
                    "NPC Distance",
                    "Ore ESP",
                    "Ore Options (name, distance)",
                    "Ore Distance",
                    "Sulfur Ore",
                    "Metal Ore",
                    "Stone Ore",
                    "Collectable ESP",
                    "Collectable Options (name, distance)",
                    "Collectable Distance",
                    "Hemp",
                    "Sky Gradient",
                    "Ambience",
                    "Custom Stars (brightness, size)",
                    "Sky Color",
                    "Cloud Color",
                    "Cloud Brightness",
                    "Time Changer",
                    "Custom Moon Size",
                    "Patrol Heli ESP",
                    "Patrol Heli Health",
                    "Patrol Heli Alert",
                    "Vehicle ESP",
                    "Vehicle Options (name, distance, health)",
                    "Vehicle Distance",
                    "Minicopter",
                    "Scrap Heli",
                    "Bradley",
                    "Tool Cupboard ESP",
                    "Tool Cupboard Options (distance, upkeep, auth list, health, show enemy, only show close)",
                    "Tool Cupboard Distance",
                    "Auto Turret ESP",
                    "Auto Turret Options (distance, auth list, health, show enemy, only show close, ignore authed)",
                    "Auto Turret Distance",
                    "Sleeping Bag ESP",
                    "Sleeping Bag Options (distance, show owner, show enemy)",
                    "Sleeping Bag Distance"
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Toggle Layers (construction, terrain, tree, clutter, world, deployed, water)",
                    "Instant Loot",
                    "Zoom",
                    "Add/Remove Friend",
                    "Add/Remove Enemy",
                    "Enemy In Render Alert",
                    "Auto Revive Team",
                    "Instant Revive",
                    "Silent Farm",
                    "Auto Farm",
                    "Auto Med",
                    "No Sway",
                    "Silent Viewmodel",
                    "FOV Changer",
                    "AntiAim (up, down)",
                    "Minimum Desync",
                    "Omni Sprint",
                    "Infinite Jump",
                    "No Collision",
                    "Speedhack",
                    "Noclip (toggle, hold)",
                    "Small Capsule",
                    "Interactive Debug (toggle, hold)",
                    "Spiderman",
                    "OnLadder",
                    "Debug Camera",
                    "No Fall",
                    "Spam Break Legs",
                    "Flyhack Bar",
                    "Flyhack Antikick",
                    "Menu Accent",
                    "Custom Aspect Ratio",
                    "Smooth Zoom",
                    "Font Size",
                    "Watermark",
                    "Notifications",
                    "Menu Keybind",
                    "Current Font (default, pixel, pixel2)",
                    "Desync Indicator (always, active)",
                    "Manipulated Indicator",
                    "Noclip Indicator",
                    "Interactive Indicator",
                    "Can Shoot Indicator",
                    "Reload Indicator"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "REQUIRES USB & FREE SPOOFER"},
        ],
    },
    {
        name: "Quantum Public",
        game: "Rust",
        image: rustquantumpublic,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Quantum-Public",
         media: [
            {"url": "https://www.youtube.com/embed/_rwD0a-CC-w?si=_P_3aAmrgYLszgPf"},
        ],
        day: "10.99",
        week: "49.99",
        month: "99.99",
        life: "1200",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Internal",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "AIMBOT",
                "items": [
                    "Aimbot",
                    "PSilent",
                    "PSilent Heli",
                    "Smoothing",
                    "Recoil Comp",
                    "Prediction",
                    "Hit Chance",
                    "Auto Shoot Enemy",
                    "Autofire",
                    "Silent No Spread",
                    "Bone Override"
                  ]
            },
            {
                "title": "AIM VISUALS",
                "items": [
                    "Simulate Projectiles",
                    "Aim Marker",
                    "Crosshair",
                    "Fov Circle",
                    "Target Snapline"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enemy Tracker",
                    "Players",
                    "Scientists",
                    "Wounded",
                    "Sleeper",
                    "Friends",
                    "Radar",
                    "Rainbow Hands",
                    "FOV Arrows",
                    "Looking at me Alert",
                    "Chams (Rainbow/Single Color)",
                    "Skeleton",
                    "Username",
                    "Box ESP",
                    "Held Item",
                    "Health Bar",
                    "Hotbar",
                    "Clothing",
                    "View Direction",
                    "Raid ESP",
                    "All Resources",
                    "All Lootables",
                    "All World Vehicles",
                    "All Deployables (Including Stash/Trap ESP)"
                  ]
            },
            {
                "title": "AUTOMATION",
                "items": [
                    "Silent Melee Ores",
                    "Silent Melee Tree",
                    "Silent Melee Players",
                    "Silent Melee Animals",
                    "Auto Upgrade",
                    "Auto Clone",
                    "Auto Suicide",
                    "Auto Knock",
                    "Auto Open",
                    "Auto Free Crates",
                    "Auto Stack Wood",
                    "Auto Stack Stone",
                    "Auto Stack Metal",
                    "Auth Auth Codes",
                    "Auth Lock Codes",
                    "Auto Collect",
                    "Auto Loot Corpse",
                    "Auto Disarm Landmines",
                    "Disable Recycler",
                    "Empty Inventory",
                    "Auto Run"
                  ]
            },
            {
                "title": "UTILITY",
                "items": [
                    "Debug Camera",
                    "3rd Person",
                    "Flyhack",
                    "Interactive Noclip",
                    "Teleport",
                    "Silent Walk",
                    "Omnisprint",
                    "Anti-Flyhack Violations",
                    "Anti-Noclip Violations",
                    "Anti-Speed Violations",
                    "Anti-Barrier Violations",
                    "Spinbot",
                    "Spiderman",
                    "Smaller Hitbox",
                    "Infinte Jump",
                    "Walk on Water",
                    "Turn Placeables",
                    "Increase Height",
                    "No Fall",
                    "No Slowdown",
                    "No Blackout",
                    "No Collision",
                    "Sign Painter",
                    "Instant Loot",
                    "Instant Revive",
                    "Instant Drink",
                    "Instant Med",
                    "Instant Med Friend"
                  ]
            },
            {
                "title": "COMBAT",
                "items": [
                    "Recoil",
                    "Full Auto",
                    "Fast Fire",
                    "No Sway",
                    "No Spread",
                    "Increase Velocity",
                    "Can Shoot",
                    "Spoof Hit distance",
                    "Thick Bullet",
                    "Weapon Spammer",
                    "No Bolt Cycle",
                    "Instant Eoka",
                    "Unlock Angles",
                    "Aim in Mountable",
                    "No Melee Slowdown",
                    "No Melee Punch",
                    "2x Melee Distance",
                    "Hammer Spam",
                    "No Aimcone",
                    "Fast Bow",
                    "Instant Charge",
                    "No Movement Penalty"
                  ]
            },
            {
                "title": "GAME VISUALS",
                "items": [
                    "Day Hack",
                    "Sky Changer",
                    "Ambient",
                    "Bright Stars",
                    "FOV Changer",
                    "Zoom",
                    "Draw Colliders",
                    "Hardcore Map",
                    "Hardcore Compass",
                    "Disable Grass/Weather Effects"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Save/Load Legit Config",
                    "Save/Load Rage Config",
                    "Menu Theme Color",
                    "Hotkey to Switch Configs"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SPOOFER INCLUDED"},
        ],
    },
    {
        name: "Rustsense",
        game: "Rust",
        image: rustrustsense,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Rustsense-Script",
         media: [
            {"img": rustrustsense},
        ],
        week: "12",
        month: "30",
        life: "60",
        features: [
            {
                "title": "FEATURES",
                "items": [
                    "Flawless recoil control for all weapons & attachments",
                    "Supports both old & new recoil",
                    "X & Y control amount",
                    "Auto detection for all weapons & scopes",
                    "Smart detection (improves weapon detection consistency and performance by remembering what was previously scanned)",
                    "Customizable randomization & humanization",
                    "Customizable keybinds (for cycling weapons, scopes & muzzles)",
                    "Configuration system (customizable configs)",
                    "Hip-fire control",
                    "Empty box tool",
                    "Unload on key (leaves no traces)"
                  ]
            },
            {
                "title": "SECURITY",
                "items": [
                    "Unique client & module builds created every day for each user",
                    "Protection from client-side script check tools",
                    "Runs in the background",
                    "Completely hidden from task manager & processes",
                    "Doesn't interact with the game in any way",
                    "User information encrypted",
                    "Compatible with hardware-id spoofers",
                    "Automated client login"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    {
        name: "Quantum Private",
        game: "Rust",
        image: rustquantumprivate,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Quantum-private",
         media: [
            {"url": "https://www.youtube.com/embed/_rwD0a-CC-w?si=_P_3aAmrgYLszgPf"},
        ],
        month: "12",
        invite: "400",
        life: "1200",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Internal",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "AIMBOT",
                "items": [
                    "Memory Aimbot",
                    "PSilent",
                    "PSilent Heli",
                    "Smoothing",
                    "Recoil Comp",
                    "Prediction",
                    "Hit Chance",
                    "Auto Shoot Enemy",
                    "Autofire",
                    "Magic Bullet (Manipulation)",
                    "Magic Bullet Heli",
                    "Simulate Angles",
                    "Instant Kill",
                    "Silent No Spread",
                    "Bone Override",
                    "Shoot through Walls (Ladder Magic)",
                    "Reflect Bullets"
                  ]
            },
            {
                "title": "AIM VISUALS",
                "items": [
                    "Simulate Projectiles",
                    "Aim Marker",
                    "Crosshair (NAZI)",
                    "Fov Circle",
                    "Target Snapline",
                    "Bullet Tracers"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enemy Tracker",
                    "Players",
                    "Scientists",
                    "Wounded",
                    "Sleeper",
                    "Friends",
                    "Radar",
                    "Rainbow Hands",
                    "FOV Arrows",
                    "Looking at me Alert",
                    "Chams (Rainbow/Single Color)",
                    "Skeleton",
                    "Username",
                    "Box ESP",
                    "Held Item",
                    "Health Bar",
                    "Hotbar",
                    "Clothing",
                    "View Direction",
                    "Raid ESP",
                    "All Resources",
                    "All Lootables",
                    "All World Vehicles",
                    "All Deployables (Including Stash/Trap ESP)"
                  ]
            },
            {
                "title": "AUTOMATION",
                "items": [
                    "Silent Melee Ores",
                    "Silent Melee Tree",
                    "Silent Melee Players",
                    "Silent Melee Animals",
                    "Auto Upgrade",
                    "Auto Clone",
                    "Auto Suicide",
                    "Auto Knock",
                    "Auto Open",
                    "Auto Free Crates",
                    "Auto Stack Wood",
                    "Auto Stack Stone",
                    "Auto Stack Metal",
                    "Auth Auth Codes",
                    "Auth Lock Codes",
                    "Auto Collect",
                    "Auto Loot Corpse",
                    "Auto Disarm Landmines",
                    "Disable Recycler",
                    "Empty Inventory",
                    "Auto Loot (Select Weapons, Resources ETC.)",
                    "Auto Run",
                    "Auto Refill Jack Hammer",
                    "Auto Walk to Marker",
                    "Auto Farmbot Ore (Runs to nearest selected node and farms, Repeat..)"
                  ]
            },
            {
                "title": "UTILITY",
                "items": [
                    "Debug Camera",
                    "3RD Person",
                    "Flyhack",
                    "Interactive Noclip",
                    "Teleport",
                    "Silent Walk",
                    "Omnisprint",
                    "Anti-Flyhack Violations",
                    "Anti-Noclip Violations",
                    "Anti-Speed Violations",
                    "Anti-Barrier Violations",
                    "Spinbot",
                    "Spiderman",
                    "Smaller Hitbox",
                    "Infinte Jump",
                    "Walk on Water",
                    "Turn Placeables",
                    "Increase Height",
                    "No Fall",
                    "No Slowdown",
                    "No Blackout",
                    "No Collision",
                    "Sign Painter",
                    "Instant Loot",
                    "Instant Revive",
                    "Instant Drink",
                    "Instant Med",
                    "Instant Med Friend"
                  ]
            },
            {
                "title": "COMBAT",
                "items": [
                    "Recoil",
                    "Full Auto",
                    "Fast Fire",
                    "No Sway",
                    "No Spread",
                    "Increase Velocity",
                    "Can Shoot",
                    "Spoof Hit distance",
                    "Thick Bullet",
                    "Weapon Spammer",
                    "No Bolt Cycle",
                    "Instant Eoka",
                    "Unlock Angles",
                    "Aim in Mountable",
                    "No Melee Slowdown",
                    "No Melee Punch",
                    "2x Melee Distance",
                    "Hammer Spam",
                    "No Aimcone",
                    "Fast Bow",
                    "Instant Charge",
                    "No Movement Penalty"
                  ]
            },
            {
                "title": "GAME VISUALS",
                "items": [
                    "Day Hack",
                    "Sky Changer",
                    "Ambient",
                    "Bright Stars",
                    "FOV Changer",
                    "Zoom",
                    "Draw Colliders",
                    "Hardcore Map",
                    "Hardcore Compass",
                    "Custom Melee Effect",
                    "Disable Grass/Weather Effects",
                    "No Server UI (Admins can't crash game)"
                  ]
            },
            {
                "title": "PLAYER LIST",
                "items": [
                    "Shows all players in the server",
                    "Shows players coordinate on Map",
                    "Displays if the server has Cerberus protection"
                  ]
            },
            {
                "title": "CONFIG",
                "items": [
                    "Save/Load Legit Config",
                    "Save/Load Rage Config",
                    "Menu Theme Color",
                    "Hotkey to Switch Configs"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SPOOOFER INCLUDED"},
        ],
    },

    //TARKOV
    {
        name: "ABS Terralabs",
        game: "Escape from Tarkov",
        image: tarkovterralabs,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/ABS-Terralabs",
        media: [
            {"img": tarkovterralabs},
        ],
        day: "8",
        days: "22",
        week: "50",
        month: "180",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Aim Prediction",
                    "Aim Key",
                    "Head Lock"
                  ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "Player ESP",
                    "Scav ESP",
                    "Scav Boss ESP",
                    "SkeletonBox",
                    "Visibility Checks",
                    "Health ESP",
                    "Player Weapon + Ammo Count",
                    "Player ESP Distance",
                    "Item ESP",
                    "Medical",
                    "Food",
                    "Valuables",
                    "Keys",
                    "Task",
                    "Ammo",
                    "Attachments",
                    "Other",
                    "Container ESP",
                    "Grenade ESP",
                    "Extraction ESP",
                    "Crosshair"
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Streamproof",
                    "Night Vision",
                    "Thermal Vision",
                    "No Visor",
                    "Aim Warning"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    {
        name: "Quantum Private",
        game: "Escape from Tarkov",
        image: tarkovquantumprivate,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Quantum-Private-EFT",
        media: [
            {"img": tarkovquantumprivate},
        ],
        day: "12",
        week: "35",
        month: "80",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Internal",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "ESP FEATURES",
                "items": [
                    "Player ESP",
                    "Scav ESP",
                    "Name ESP",
                    "Skeleton ESP",
                    "Box ESP",
                    "Distance ESP",
                    "Item ESP",
                    "Quest Items",
                    "Extraction ESP",
                    "Grenade ESP"
                  ],
            },
            {
                "title": "AIMBOT FEATURES",
                "items": [
                    "Aimbot",
                    "PSilent",
                    "Prediction",
                    "Aim Spot Changer",
                    "Aim key",
                    "Draw FoV",
                    "FoV Slider",
                    "Smoothing",
                    "Aim Distance",
                    "Aim Mode",
                    "Draw Crosshair"
                  ]
            },
            {
                "title": "RADAR FEATURES",
                "items": [
                    "Toggle",
                    "Radar background",
                    "Radar Outline",
                    "Radar Cross",
                    "Radar Zoom"
                  ]
            },
            {
                "title": "MEMEORY FERATURES",
                "items": [
                    "No Recoil",
                    "No Spread",
                    "No Sway",
                    "Instant Aim",
                    "No Gun Movement",
                    "Mag Drills",
                    "No Malfunctions",
                    "Run & Gun",
                    "Far Grenades",
                    "Fly Up/Down",
                    "Fly Level",
                    "Instant Examine",
                    "Infinite Search",
                    "Unlimited Oxygen",
                    "Unlimited Stamina",
                    "No Sprint Block",
                    "Loot Through Walls",
                    "Silent Loot",
                    "No Weight",
                    "No Inertia",
                    "Night Vision",
                    "No Visor",
                    "Day Hack",
                    "FoV Changer",
                    "High Jump"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SPOOFER INCLUDED"},
        ],
    },
    {
        name: "Coffee Lite",
        game: "Escape from Tarkov",
        image: tarkovcoffeelite,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Coffee-EFT-Lite",
        media: [
            {"img": tarkovcoffeelite},
        ],
        day: "12",
        week: "35",
        month: "80",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Undetected",
                    "HWID Spoofer Included",
                    "Customizable",
                    "Low CPU Usage",
                    "Internal",
                    "Cheap & Simple Usage",
                    "Unique Bypass"
                  ]
            },
            {
                "title": "ESP FEATURES",
                "items": [
                    "Player ESP",
                    "Scav ESP",
                    "Name ESP",
                    "Skeleton ESP",
                    "Box ESP",
                    "Distance ESP",
                    "Item ESP",
                    "Quest Items",
                    "Extraction ESP",
                    "Grenade ESP"
                  ],
            },
            {
                "title": "AIMBOT FEATURES",
                "items": [
                    "Aimbot",
                    "PSilent",
                    "Prediction",
                    "Aim Spot Changer",
                    "Aim key",
                    "Draw FoV",
                    "FoV Slider",
                    "Smoothing",
                    "Aim Distance",
                    "Aim Mode",
                    "Draw Crosshair"
                  ]
            },
            {
                "title": "RADAR FEATURES",
                "items": [
                    "Toggle",
                    "Radar background",
                    "Radar Outline",
                    "Radar Cross",
                    "Radar Zoom"
                  ]
            },
            {
                "title": "MEMEORY FERATURES",
                "items": [
                    "No Recoil",
                    "No Spread",
                    "No Sway",
                    "Instant Aim",
                    "No Gun Movement",
                    "Mag Drills",
                    "No Malfunctions",
                    "Run & Gun",
                    "Far Grenades",
                    "Fly Up/Down",
                    "Fly Level",
                    "Instant Examine",
                    "Infinite Search",
                    "Unlimited Oxygen",
                    "Unlimited Stamina",
                    "No Sprint Block",
                    "Loot Through Walls",
                    "Silent Loot",
                    "No Weight",
                    "No Inertia",
                    "Night Vision",
                    "No Visor",
                    "Day Hack",
                    "FoV Changer",
                    "High Jump"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "SPOOFER INCLUDED"},
        ],
    },

    //Rainbow six
    {
        name: "Klar.gg",
        game: "Rainbow Six Siege",
        image: rainbowklar,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/klar-R6-Lite",
        media: [
            {"img": rainbowklar},
        ],
        day: "5",
        week: "15",
        month: "30",
        features: [
            {
                "title": "ESP",
                "items": [
                    "No Recoil",
                    "No Spread",
                    "No Sway",
                    "Instant Aim",
                    "No Gun Movement",
                    "Mag Drills",
                    "No Malfunctions",
                    "Run & Gun",
                    "Far Grenades",
                    "Fly Up/Down",
                    "Fly Level",
                    "Instant Examine",
                    "Infinite Search",
                    "Unlimited Oxygen",
                    "Unlimited Stamina",
                    "No Sprint Block",
                    "Loot Through Walls",
                    "Silent Loot",
                    "No Weight",
                    "No Inertia",
                    "Night Vision",
                    "No Visor",
                    "Day Hack",
                    "FoV Changer",
                    "High Jump"
                  ]
            },
            {
                "title": "GADGETS",
                "items": [
                    "Pings",
                    "Size Slider",
                    "Traps",
                    "Breach Utility",
                    "Observation",
                    "Shields",
                    "Objectives",
                    "Misc"
                  ]
            },

        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },
    {
        name: "Armycheats",
        game: "Rainbow Six Siege",
        image: rainbowarmy,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Armycheats-R6-Lite",
        media: [
            {"url": "https://www.youtube.com/embed/i32N8eGkJbY?si=mEHZD-SIqsvXlIBN"},
        ],
        day: "8",
        week: "30",
        month: "60",
        features: [
            {
                "title": "ESP",
                "items": [
                    "Player ESP",
                    "HP",
                    "Name",
                    "Head Circle",
                    "Operator ESP",
                    "Tag ESP",
                    "Distance",
                    "Skeletons",
                    "Skeleton Visible/Hidden Color",
                    "World ESP",
                    "Gadget ESP",
                    "Drones",
                    "Cameras",
                    "Lethals",
                    "Stuns",
                    "Breaches",
                    "Utility"
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "No Recoil",
                    "Spread Modifier",
                    "Spread Value",
                    "No Pullout"
                  ]
            },

        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    //Dayz
    {
        name: "Intellisense",
        game: "Dayz",
        image: dayzintel,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/intellisense-DayZ",
        media: [
            {"img": dayzintel},
        ],
        day: "7",
        week: "25",
        month: "55",
        features: [
            {
                "title": "LEGIT TAB",
                "items": [
                    "Enable Aim [ & Hotkey ]",
                    "Smoothness Adjustment",
                    "Bone Pick [ Head, Chest, Pelvis, Random ]",
                    "Max Distance Adjustment",
                    "Snap Lines",
                    "FOV Adjustment [ Toggleable | Colored | Shape ]"
                  ]
            },
            {
                "title": "RAGE TAB",
                "items": [
                    "Enable Silent Aim [ & Hotkey ]",
                    "Enable Targetting [ & Hotkey ]",
                    "Bone Pick [ Head, Chest, Pelvis, Random ]",
                    "Hitscan [ & Server Log Evasion ]",
                    "Max Distance Adjustment",
                    "FOV Adjustment [ Toggleable | Colored | Shape ]"
                  ]
            },
            {
                "title": "MISC TAB",
                "items": [
                    "Adjust Day Time [ Sun Long/Lat, Local Time ]",
                    "No Grass",
                    "Unlock Thirdperson",
                    "Speedhack [ Hotkey ]",
                    "Teleport Loot [ Hotkey ]",
                    "Loot Through Walls / Freecam [ Hotkey ]",
                    "Customizable Speed [Faster/Slower] [ Hotkey ]",
                    "Customizable Movements [Left/Right/Forward/Backwards] [ Hotkey ]"
                  ]
            },
            {
                "title": "VISUALS TAB",
                "items": [
                    "Automatic Admin Detection",
                    "Ignore Infected",
                    "Ignore Animals",
                    "Max Distance Adjustment",
                    "Max Item Distance Adjustment",
                    "Display Items [ Colored ]",
                    "Filter Items [ All, Weapon, Clothing, Ammo/Magazines, Tools, Building, Crafting, Food & More ]",
                    "Bounding Box [ Colored ]",
                    "Filled Box [ Colored ]",
                    "Cornered Box [ Colored ]",
                    "Head Hitbox [ Colored ]",
                    "Skeleton [ Colored ]",
                    "Entity Name [ Colored ]",
                    "Item In Hands [ Colored ]",
                    "Entity Type Name [ Colored ]",
                    "Distance [ Colored ]"
                  ]
            },
            {
                "title": "CFG TAB",
                "items": [
                    "Crosshair [ Colored ]",
                    "Hide Watermark",
                    "Hide Visual Preview",
                    "Save/Load Config"
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    //SPOOFERS
    {
        name: "Exception",
        game: "Spoofer",
        image: spooferexception,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Exception-Spoofer",
        media: [
            {"url": "https://www.youtube.com/embed/wcuccavRugs?si=VxGLFgXZqsIzxvcM"},
        ],
        day: "4.99",
        week: "12.99",
        month: "24.99",
        features: [
            {
                "title": "SPOOFS",
                "items": [
                    "Disk",
                    "USB",
                    "Monitor",
                    "Nvidia GPU (does support and GPU)",
                    "RAM",
                    "MOTHERBOARD",
                    "NetworkEFI",
                    "Anticheats: Easy Anti-Cheat, BattlEye Anti-Cheat, MRAC Anti-Cheat",
                    "Supported OS: Windows 10-11 (19041-19045) (Home/Pro)",
                    "Supported Interfaces: NVMe (Non-RAID), SATA (AHCI)"
                  ]
                
            },

        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    {
        name: "Perm Spoofer",
        game: "Spoofer",
        image: spooferperm,
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Fivestar-Perm-Spoofer",
        media: [
            {"img": permimg},
        ],
        day: "25",
        life: "100",
        features: [
            {
                "title": "SPOOFS",
                "items": [
                    "Disk",
                    "USB",
                    "Monitor",
                    "Nvidia GPU (does support and GPU)",
                    "RAM",
                    "MOTHERBOARD",
                    "NetworkEFI",
                    "Anticheats: Easy Anti-Cheat, BattlEye Anti-Cheat, MRAC Anti-Cheat",
                    "Supported OS: Windows 10-11 (19041-19045) (Home/Pro)",
                    "Supported Interfaces: NVMe (Non-RAID), SATA (AHCI)"
                  ]
                
            },

        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    //CS2
    {
        name: "Fecurity",
        game: "Counter Strike 2",
        image: csgofecurity,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/CS2-Fecurity",
        media: [
            {"url": "https://www.youtube.com/embed/thu_aaIjkG8?si=BHO6BeYtdKlSzqTg"},
        ],
        day: "4",
        week: "10",
        month: "17",
        features: [
            {
                "title": "AIMBOT GENERAL",
                "items": [
                    "Enable (Enables aimbot)",
                    "Aim at shoot",
                    "Visible Only (Aim for visible bones only)",
                    "Enemy only (Aim for enemies only)",
                    "Aim horizontal speed",
                    "Aim vertical speed",
                    "Recoil compensation"
                  ]
                
            },
            {
                "title": "SELECT",
                "items": [
                    "Draw FOV",
                    "FOV",
                    "Target switch delay",
                    "Hitscan coefficient"
                  ]
                
            },
            {
                "title": "BINDS",
                "items": [
                    "Hitbox priority",
                    "Aim key",
                    "Second aim key",
                  ]
            },
            {
                "title": "TRIGGER",
                "items": [
                    "Mode (Disable / Enable)",
                  ]
            },
            {
                "title": "VISUALS GENERAL",
                "items": [
                    "Visuals enabled",
                    "Enemy only (Visuals for enemies only)",
                  ]
            },
            {
                "title": "CHARACTER",
                "items": [
                    "Box (Draw player box)",
                    "Box outline",
                    "Health (Draw health bar)",
                    "Shield (Draw shield bar)",
                    "Skeleton (Draw skeleton)",
                    "Maximum distance",
                    "Player info (Nickname, Distance, etc)"
                  ]
                
            },
            {
                "title": "MISC",
                "items": [
                    "Auto jump",
                    "Auto pistol",
                    "Auto accept (Auto accepting matchmaking)",
                    "Add all skins (Intel only atm)"
                  ]
                
            },
            {
                "title": "UNTRUSTED POLICY",
                "items": [
                    "Untrusted mode (Enables untrusted mode)",
                    "No spread",
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "AVX & UEFI REQUIRED"},
        ],
    },

    //APEX
    {
        name: "Fecurity",
        game: "Apex Legends",
        image: apexfecurity,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Apex-Legends-Fecurity",
        media: [
            {"img": apexfecurity},
        ],
        day: "4",
        week: "20",
        month: "35",
        features: [
            {
                "title": "FEATURES",
                "items": [
                    "Grenade ESP",
                    "Smart loot",
                    "Player ESP",
                    "Health",
                    "Distance",
                    "Nickname",
                    "Skeleton",
                    "2D Box",
                    "FOV",
                    "Aim-Assist"
                  ]
                
            },
        ],
        requirments: [
            {"card": "INTEL only"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    //BATTLE
    {
        name: "Fecurity",
        game: "Battlebit",
        image: battlefecurity,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Battlebit-Fecurity",
        media: [
            {"img": battlefecurity},
        ],
        day: "6.50",
        week: "28",
        month: "50",
        features: [

            {
                "title": "VISUALS",
                "items": [
                    "Enabled - Visuals enabled",
                    "Enemy only - Visuals for enemies only",
                    "Box - Draw player box",
                    "Box outline",
                    "Health - Draw health bar",
                    "Skeleton - Draw skeleton",
                    "Maximum Distance - Distance for visuals, skeleton, box",
                    "Player info - Nickname, distance, etc",
                    "AIMBOT (ONLY SILENT)",
                    "Aimbot at shoot",
                    "Magic aimbot",
                    "Bind aimbot",
                    "MISC",
                    "Ovveride FOV - Overrides field of view",
                    "FOV",
                    "EXPLOITS",
                    "Speed hack",
                    "Instant hit",
                    "Instant ads",
                    "Unlock fire modes",
                    "No spread",
                    "No recoil",
                    "No boltcatch",
                    "No ballistic",
                    "DPI"
                  ]
                
            },

        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
        ],
    },

    //MW
    {
        name: "Fecurity",
        game: "Modern Warfare",
        image: mwfecurity,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Modernwarfare-Fecurity",
        media: [
            {"img": mwfecurity},
        ],
        day: "4",
        days: "6",
        week: "10",
        month: "20",
        months: "55",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Enable.",
                    "Aim at Shoot.",
                    "Aim at Scope.",
                    "Visbile only.",
                    "Two types of aimbot input. (Relative, absolute).",
                    "Controllable speed (0-100%).",
                    "Recoil compensation.",
                    "Draw FOV.",
                    "Gamepad Support (Only xbox at the moment).",
                    "Prediction.",
                    "Setupable FOV (0-15).",
                    "Ignore Knocked Targets.",
                    "Target Switch Delay.",
                    "Absolutely unique hitbox system.",
                    "Two aim keys."
                  ]
                
            },
            {
                "title": "VISUALS",
                "items": [
                    "Enable.",
                    "Enemy only.",
                    "Box.",
                    "OOF indicators (out of view).",
                    "Health.",
                    "Shield.",
                    "Skeleton.",
                    "Skeleton Thickness.",
                    "Glow.",
                    "Fill Glow.",
                    "Visibility check.",
                    "Glow thickness.",
                    "Type (Default, textured, thermal, noisy).",
                    "Distance.",
                    "Player Info (Nickname, distance, weapon, ping, kills, rank)."
                  ]
            },
            {
                "title": "LOOT",
                "items": [
                    "Enable.",
                    "Distance.",
                    "Categories (Other, ammo, weapon, armor, money, self, booster, gasmask, killstreak, tactical)."
                  ]
            },
            {
                "title": "MISC",
                "items": [
                    "Unlocker.",
                    "Custom Clantag.",
                    "Distance Unit (Feet, yard, meter)."
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "ONLY UEFI"},
        ],
    },
    {
        name: "Fecurity UNLOCKER",
        game: "Modern Warfare",
        image: mwfecurityunlocker,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Unlocker-Tool-MW",
        media: [
            {"img": mwfecurityunlocker},
        ],
        month: "10",
        features: [
            {
                "title": "INFO",
                "items": [
                    "Unlocks everything so you can play with anything you want.",
                  ]
            },

        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "ONLY UEFI"},
        ],
    },

    //VALORANT
    {
        name: "Coffee Full",
        game: "Valorant",
        image: valcoffee,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Coffee-Valorant",
        media: [
            {"img": valcoffee},
        ],
        day: "10",
        week: "50",
        month: "90",
        features: [
            {
                "title": "AIMBOT",
                "items": [
                    "Aim FOV",
                    "Crosshair",
                    "Aim Bones (Nearest)"
                ]
            },
            {
                "title": "VISUALS",
                "items": [
                    "ESP Font Size",
                    "Player BOX",
                    "Player Healthbar",
                    "Player Name",
                    "Player Weapon",
                    "Player Agent",
                    "Player Skeleton",
                    "Player Glow",
                    "Glow Intensity",
                    "Visibility Check"
                ]
            },
            {
                "title": "MISC",
                "items": [
                    "CONFIG SYSTEM",
                  ]
            },
        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 10/11"},
            {"other": "ONLY UEFI"},
        ],
    },
    {
        name: "Fecurity",
        game: "Valorant",
        image: valfecurity,
        
        status: "Undetected",
        stripe: "https://fivestar.cheating.store/product/Fecurity-Valorant",
        media: [
            {"img": valfecurity},
        ],
        day: "12",
        days: "30",
        week: "60",
        month: "80",
        features: [
            {
                "title": "FEATURES",
                "items": [
                    "Player ESP",
                    "Health",
                    "Armor",
                    "Distance",
                    "Nickname",
                    "Skeleton",
                    "Aimbot",
                    "Smooth",
                    "No recoil",
                    "2D Box",
                    "FOV",
                    "Chams",
                    "Weapon ESP",
                    "TriggerBOT",
                    "Spread Prediction for Trigger",
                    "Trigger Crosshair",
                    "Shield Checkbox",
                    "Trigger Crosshair customization",
                    "Trigger delay working by MS"
                ]
            },

        ],
        requirments: [
            {"card": "INTEL & AMD"},
            {"winver": "WINDOWS 1809/11"},
        ],


    }
    
]
