import apex from '../images/apexfivestar.webp'
import rust from '../images/rustfivestar.webp'
import rainbow from '../images/rainbowfivestar.webp'
import tarkov from '../images/tarkovfivestar.webp'
import cs2 from '../images/counterstrikefivestar.webp'

export const PopularGames = [
    {
        "game": "Rust",
        "image": rust
    },
    {
        "game": "Escape from Tarkov",
        "image": tarkov
    },
    {
        "game": "Counter Strike 2",
        "image": cs2
    },
    {
        "game": "Rainbow Six Siege",
        "image": rainbow
    }
]