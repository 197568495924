import React, {useState, useEffect, useRef} from "react";
import { BrowserRouter as Router, Routes, Link, Route} from 'react-router-dom';
import {Footer} from '../components/footer.js'
import {data} from '../components/cheats-data.js';
import {Games} from '../components/games.js';

export const Unknown = () => {
 

 return (
   <>
   <div className="error-title">404</div>
   <div className="error-sub">This link has either been removed or does not exist.</div>
  
    </>

 )

}