import React, {useState} from "react";
import { BrowserRouter as Router, Routes, Link, Route} from 'react-router-dom';
import Logo from '../images/logo.webp';
import {data} from './cheats-data.js';



export const Footer = () => {

   const sanitizeForURL = (str) => {
      // Regular expression to match any character that is not a letter, number, underscore, or hyphen
      const invalidCharRegex = /[^a-zA-Z0-9_\-]/g;
   
      // Replace invalid characters with an empty string
      return str.replace(invalidCharRegex, '');
    };

   const getGameURL = (cheatGame) => {
      return sanitizeForURL(cheatGame);
   };

   const getCheatURL = (cheatName) => {
      return sanitizeForURL(cheatName);
   }

 return (
    <footer>
    <div className="footer-info">
       <div className="footer-title"><img src={Logo} />FIVESTARSOLUTIONS</div>
       <div className="footer-desc">Top tier gaming cheats meticulously crafted by highly experienced developers to give you an extreme advantage over any competition.</div>
    </div>
    <div className="footer-lists">
      <ul className="footer-list">
          <li className="footer-list-title">NAVIGATION</li>
          <Link to="/"><li>Home</li></Link>
          <Link to="/products"><li>Store</li></Link>
          <Link to="/status"><li>Status</li></Link>
          <Link to="/faq"><li>FAQ</li></Link>
          <Link to="https://discord.gg/fivestarsolution"><li>Support</li></Link>
       </ul>
       <ul className="footer-list">
          <li className="footer-list-title">CONTACT</li>
          <Link to="https://discord.gg/fivestarsolution"><li>Discord</li></Link>
          <Link to="https://www.youtube.com/@shadower./videos"><li>Youtube</li></Link>
          <Link to="https://www.tiktok.com/@fivestarsolutions?lang=en"><li>Tiktok</li></Link>
       </ul>
       <ul className="footer-list">
          <li className="footer-list-title">PRODUCTS</li>
          {data.map((cheat) => {
            return <Link to={"/products/" + getGameURL(cheat.game) + "/" + getCheatURL(cheat.name)}><li>{cheat.name} | {cheat.game}</li></Link>
          })}
       </ul>
    </div>
 </footer>

 )

}